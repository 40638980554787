.ARCO {
    font-family: "ARCO";
  }


.JMADH {
  font-family: 'JustMeAgainDownHere-Regular'
}

.Rubik {
  font-family: 'Rubik, sans-serif'
}
  

.flower {
  z-index: 50;
}